export const TEN_MINUTES = 600000;
export const FIVE_MINUTES = 300000;
export const ONE_DAY_AGO = new Date(Date.now() - 24 * 60 * 60 * 1000);

export const GET_GLUCOSE_URL =
  "https://us-central1-quill-food-calculator.cloudfunctions.net/get-glucose-reading";
export const LOG_FOOD_URL =
  "https://us-central1-quill-food-calculator.cloudfunctions.net/log-food-and-dose";
export const READ_FOOD_LOG_URL =
  "https://us-central1-quill-food-calculator.cloudfunctions.net/read-food-and-dose";
export const OBTAIN_AUTH_URL =
  "https://us-central1-quill-food-calculator.cloudfunctions.net/obtain-auth-key";
export const READ_GLUCOSE_HISTORY_CHART =
  "https://us-central1-quill-food-calculator.cloudfunctions.net/read-glucose-history-chart";

export const MEAL_OPTIONS: MealOptions[] = [
  {
    label: "Custom",
    sensitivityFactor: 50,
    carbRatioNumerator: 1,
    carbRatioDenominator: 17,
    targetBloodSugar: 100,
    custom: true,
  },
  {
    label: "Breakfast",
    sensitivityFactor: 50,
    carbRatioNumerator: 1,
    carbRatioDenominator: 15,
    targetBloodSugar: 100,
  },
  {
    label: "AM snack",
    sensitivityFactor: 50,
    carbRatioNumerator: 1,
    carbRatioDenominator: 17,
    targetBloodSugar: 100,
  },
  {
    label: "Lunch",
    sensitivityFactor: 50,
    carbRatioNumerator: 1,
    carbRatioDenominator: 17,
    targetBloodSugar: 100,
  },
  {
    label: "PM snack",
    sensitivityFactor: 50,
    carbRatioNumerator: 1,
    carbRatioDenominator: 17,
    targetBloodSugar: 100,
  },
  {
    label: "Dinner",
    sensitivityFactor: 50,
    carbRatioNumerator: 1,
    carbRatioDenominator: 17,
    targetBloodSugar: 100,
  },
  {
    label: "Night snack",
    sensitivityFactor: 50,
    carbRatioNumerator: 1,
    carbRatioDenominator: 17,
    targetBloodSugar: 120,
  },
];

export interface DoseInfo {
  currentBloodSugar: number;
  foodDose: number;
  correction: number;
  totalDose: number;
}

export interface AuthState {
  isAuthorized: boolean;
  apiKey: string | null;
  logVersion: number | null;
}

export interface MealOptions {
  label: string;
  sensitivityFactor: number;
  carbRatioNumerator: number;
  carbRatioDenominator: number;
  targetBloodSugar: number;
  custom?: boolean;
}

export interface BloodInfo {
  init?: boolean;
  loading?: boolean;
  cached?: boolean;
  time?: string;
  trendArrow?: string;
  trendIndex?: number;
  value?: number;
}

export enum FetchResult {
  NotSent,
  Loading,
  Success,
  Empty,
  Fail,
  Stale,
}

export enum DoseMode {
  RoundUp,
  RoundDown,
  Manual,
}

export interface BloodDisplay {
  fetchResult: FetchResult;
  fetchTime: string;
  trend: string;
}

export interface FoodItemProps {
  id: number;
  type: string;
  carbs: number;
  servingSize: number;
}

export interface FoodLogEntry {
  id: number;
  hidden?: boolean;
  logVersion: number | null;
  foodItems: FoodItemProps[];
  mealTime: string;
  sensitivityFactor: number;
  carbRatioNumerator: number;
  carbRatioDenominator: number;
  currentBloodSugar: number;
  targetBloodSugar: number;
  custom: boolean;
  foodDose: number;
  correction: number;
  totalDose: number;
  administeredDose: number;
  sensorData?: BloodDisplay;
}

export interface FoodLogState {
  items: FoodLogEntry[];
  loading: boolean;
  reloading: boolean;
  error: string | null;
}

export interface ChartDataState {
  data: ChartDatum[];
  loading: boolean;
  reloading: boolean;
  error: string | null;
}

export type ChartDatum = {
  datetime: string;
  value: number;
};
